/**=====================
     04. Loader CSS Start
==========================**/
.loader-wrapper{  
  position: fixed;
  z-index: 999999;
  background: $white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .theme-loader{
    height:100px;
    width:100px;
    position:relative;   
    .loader-p {
      border: 0 solid transparent;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      position: absolute;
      top: calc(50vh - 75px);
      left: calc(50vw - 75px);
      &:before {
        content: '';
        border: 1em solid $primary-color;
        border-radius: 50%;
        width: inherit;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        animation: loader 2s linear infinite;
        opacity: 0;
        animation-delay: 0.5s;
      }
      &:after {
        content: '';
        border: 1em solid $primary-color;
        border-radius: 50%;
        width: inherit;
        height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        animation: loader 2s linear infinite;
        opacity: 0;
      }
    }
  }
@keyframes loader{
	0% {
		transform: scale(0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}
}
.loader-box {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  transition: .3s color, .3s border, .3s transform, .3s opacity;
  [class*="loader-"] {
    display: inline-block;
    width: 50px;
    height: 50px;
    color: inherit;
    vertical-align: middle;
  }
}
/**=====================
     04. Loader CSS Ends
==========================**/
